// React Login Component

import React, { useState } from 'react';

// eslint-disable-next-line react/prop-types
const Login = ({ setAuth }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    setAuth(true);
    // if (username === 'Joshcons' && password === 'JOSHcons123$') {
    //   //setAuth(true);
    // }
  };

  const formStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '300px', // Adjust the width as needed
    margin: 'auto'
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <form onSubmit={handleLogin} style={formStyles}>
        <label style={{ margin: 10 }}>
          Username:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </label>
        <label style={{ margin: 10 }}>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
