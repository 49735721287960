// assets
// import { IconBrandChrome, IconHelp } from '@tabler/icons';

// constant
// const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: '',
  type: 'group',
  // children: [
  //   {
  //     id: 'sample-page',
  //     title: '',
  //     type: 'item',
  //     url: '/sample-page',
  //     // icon: icons.IconBrandChrome,
  //     breadcrumbs: false
  //   },
  //   {
  //     id: 'documentation',
  //     title: '',
  //     type: 'item',
  //     url: 'https://codedthemes.gitbook.io/berry/',
  //     // icon: icons.IconHelp,
  //     external: true,
  //     target: true
  //   }
  // ]
};

export default other;
