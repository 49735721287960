// material-ui
// import { useTheme } from '@mui/material/styles';
import museLogo from 'assets/images/muse.png'; // Import the image

/**
 * Uncomment the following lines to use the image instead of <svg>
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO IMAGE ||============================== //

const Logo = () => {
  // const theme = useTheme();

  return (
    /**
     * Uncomment the following lines and comment out the <svg> element to use the image.
     */
    // <img src={logo} alt="Berry" width="100" />
    <img src={museLogo} alt="Muse" width="100" />
  );
};

export default Logo;
